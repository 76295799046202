import request from '@/utils/request'

export function add(data) {
  return request({
    url: 'api/warehouse',
    method: 'post',
    data
  })
}

export function del(id) {
  return request({
    url: 'api/warehouse/' + id,
    method: 'delete'
  })
}

export function edit(data) {
  return request({
    url: 'api/warehouse',
    method: 'put',
    data
  })
}

//查询当前企业/经销商指定商品在仓库中的情况
export function getGoodsById(goodsId,warehouseIds) {
  return request({
    url: 'api/warehouseGoods/goods',
    method: 'get',
    params: {
      goodsId: goodsId,
      warehouseIds: warehouseIds
    }
  })
}

export function getGoodsByParams(params) {
  return request({
    url: 'api/warehouseGoods/goods',
    method: 'get',
    params,
  })
}

// params: {
//   goodsId: goodsId,
//   warehouseIds: warehouseIds
// }

//查询当前门店指定商品在仓库中的情况
export function getGoodsByShopId(goodsId, shopId, purchasePrice) {
  return request({
    url: 'api/warehouseGoods/goodsCount',
    method: 'get',
    params: {
      goodsId,
      shopId,
      purchasePrice
    }
  })
}

export function getStockByGoodsIds(goodsIds, warehouseId) {
  return request({
    url: "api/warehouseGoods/goodsList",
    methods: "get",
    params: {
      warehouseId: warehouseId,
      goodsIds: goodsIds
    }
  })
}

export function getByErpCode(erpId) {
  return request({
    url: "api/warehouse/erpCode",
    method: "get",
    params: {
      code: erpId
    }
  })
}

export function editGoodsInfo(goodsList) {
  return request({
    url: "api/warehouseGoods/setInfo",
    method: "post",
    data: goodsList
    // goodsList: (goodsList || []).map(g => {
    //   return JSON.stringify(g);
    // })
  })
}