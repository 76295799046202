<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog" :title="isAdd ? '新增' : '编辑'" width="640px" @closed="form = null">
    <el-form ref="form" :model="form" :rules="rules" size="small" label-width="92px" v-if="form">
      
       <!-- <el-form-item label="ERP编码" v-if="$erp.enable()">
        <el-input v-model="form.erpId" maxlength="100" />
      </el-form-item> -->
      <el-form-item prop="erpId" label="ERP编码" v-if="$erp.enable()">
          <el-input v-model.trim="form.erpId" :maxlength="20" :readonly="searching" @keypress.enter.native="getByErpId" @change="getByErpId">
            <el-button slot="append" icon="el-icon-search" v-loading="searching" @click="getByErpId"></el-button>
          </el-input>
      </el-form-item>
      <el-form-item prop="licenseCode" label="本地编码">
       <el-input v-model="form.code" placeholder=" 输入仓库编码">
       </el-input>
      </el-form-item>
      <el-form-item label="仓库名称" prop="name">
        <el-input v-model.trim ="form.name" maxlength="30" placeholder="最大30字符"/>
      </el-form-item>
       <el-form-item label="仓库类型" prop="warehouseType"  >
        <el-select v-model='form.warehouseType' filterable style="width: 100%" >
          <el-option v-for="(value,key) in warehouseTypes" :key="key" :label="value" :value="key"  />
        </el-select>
      </el-form-item>
      <el-form-item label="详细地址">
        <el-input v-model="form.address" maxlength="100" />
      </el-form-item>      
      <div class="h">
        <el-form-item label="详细信息" style="width: 100%;">
          <el-input type="textarea" v-model="form.info" :maxlength="200" />
        </el-form-item>
      </div>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="dialog = false">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { add, edit, getByErpCode } from "@/api/warehouse";


export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true
    }
  },
  data() {
    let checkHasErpIdError = (rule, val, callback) => {
      if (this.errors.erpId) {
        callback("未找到指定的库房信息");
      } else {
        callback();
      }
    };
    return {
      loading: false,
      searching: false,
      dialog: false,
      form: null,
      errors: {
        erpId: false
      },
      warehouseTypes:["中央库房","零售店"],
      rules: {
        // erpId: [
        //   { required: true, message: "请输入ERP编码", trigger: "blur" },
        //   { validator: checkHasErpIdError }
        // ],
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        warehouseType:[{required: true, message: "请选择类型", trigger: "blur" }],
        code:[{ required: true, message: "请输入编码", trigger: "blur" }],
      }
    };
  },
  methods: {
    doSubmit() {      
      if (this.isAdd) {
        this.doAdd();
      } else this.doEdit();
    },
    getByErpId() {
      if (this.form.erpId) {
        this.searching = true;
        this.form.name = "";
        this.errors.erpId = false;
        this.$refs.form.validateField("erpId");
        getByErpCode(this.form.erpId)
          .then(res => {
            if (res) {
              this.form.name = res.name;
              this.form.code = res.code;
              this.errors.erpId = false;
            } else {
              this.errors.erpId = true;
            }
          })
          .catch(e => {
            this.errors.erpId = true;
          })
          .finally(_ => {
            this.searching = false;
            this.$refs.form.validateField("erpId");
          });
      }
    },
    doAdd() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.loading = true;
          add(this.form)
            .then(res => {
              this.$notify({
                title: "添加库房成功",
                type: "success",
                duration: 2500
              });
              this.loading = false;
              this.dialog = false;
              this.$parent.init();
            })
            .catch(err => {
              this.loading = false;
            });
        }
      });
    },
    doEdit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.loading = true;
          edit(this.form)
            .then(res => {
              this.$notify({
                title: "编辑库房成功",
                type: "success",
                duration: 2500
              });
              this.loading = false;
              this.dialog = false;
              this.$parent.init();
            })
            .catch(err => {
              this.loading = false;
            });
        }
      });
    },
    resetForm(form) {
      this.form = form || {
        code: "",
        erpId:'',
        name: "",
        address:'',
        warehouseType:'',
        info:'',
      };
      this.dialog = true;
    },
  }
};
</script>
